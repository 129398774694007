import React from 'react';
import PropTypes from 'prop-types';
import { Layout as AntLayout } from 'antd';

import 'typeface-lato';
import 'typeface-bree-serif';
import 'normalize.css';
import '../styles/index.less';

const Layout = ({ children, ...props }) => (
  <AntLayout {...props}>{children}</AntLayout>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
